@media only screen and (max-width: 600px) {
    #modal {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        color: black;
    }
    #cardFooter {
        width: 100%;
    }
    #formIcon {
        width: 35%;
    }
}

@media only screen and (min-width: 600px) {
    #modal {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        color: black;
        width: 50%;
        margin-left: 20%;
    }
    #cardFooter {
        width: 50%;
    }
    #formIcon {
        width: 50%;
    }
}

#formHeader {}

#slides {}