#forgotPasswordContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
}

#lockIcon {
    width: 25%;
    height: 25%;
    align-self: center;
    margin-bottom: 2em;
}

#forgotPasswordTitle {
    font-weight: bold;
    font-size: 2em;
}

#description {
    font-size: 0.75em;
}

#forgotPasswordForm {
    display: flex;
    flex-direction: column;
    align-items: center;
}