html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: #2c173d;
    color: white;
    display: flex;
    flex-direction: center;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.formField {
    width: 70%;
    height: 3em;
    margin-top: 5%;
    padding: 0 1em;
    border: none;
    border-radius: 1.5em;
    font-size: 1em;
    color: gray
}

.formButton {
    -webkit-appearance: none;
    width: 60%;
    height: 3em;
    margin-top: 2em;
    border: none;
    background-color: #27e8a7;
    color: white;
    font-weight: bold;
    font-size: 1em;
    border-radius: 2em;
}

.formLink {
    margin: 1em;
    color: white;
}