.formSlide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.formLine {
    margin-bottom: 2em;
}

.formLabel {
    font-weight: bold;
}

.formTextField {
    height: 3em;
    border-radius: 2em;
    width: 70%;
    font-size: 1em;
}